@import '../../app/variables';

$timelineBaseTransitionTime: .5s;
$timelineCardHeight: 200px;
$timelineCardOpenHeight: 500px;

$resume-bg-color: rgb(237, 240, 242);

.timeline {
    padding: 0;
    margin: 0;
}

.timeline-item {
    & > div {
        //border-color: #2196f3;
        display: block;
        padding: 15px 0 15px 20px;
        margin-left: 6em;
        //border: #dddee0 solid;
        border-color: #dddee0;
        border-style: solid;
        border-width: 0 0 0 2px;
        &:before {
            position: relative;
            top: 100px;
            float: left;
            content: "";
            box-shadow: 0 0 0 40px $resume-bg-color;
        }
    }
}

.timeline-date {
    font-family: $header-font-stack;
    font-weight: 300;
    position: relative;
    display: block;
    float: left;
    width: 120px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
}
.timeline-date-line1 {
    @extend .timeline-date;
    top: 65px;
    font-size: 25px;
    margin-left: -80px;
}
.timeline-date-line2 {
    @extend .timeline-date;
    top: 100px;
    font-size: 20px;
    margin-left: -120px;
}

.timeline-card {
    position: relative;
    display: inline-block;
    height: $timelineCardHeight;
    transition: height $timelineBaseTransitionTime;
    width: 80%;
    margin-right: 50px;
    margin-left: 20px;
    background-color: white;
    overflow: hidden;
    img {
        height: $timelineCardHeight;
        width: $timelineCardHeight;
    }
    .md-button {
        bottom: 0;
        right: 0;
        position: absolute;
        font-size: 2em;
        margin: 6px;
    }
}

.timeline-content {
    padding: 25px 30px;
    margin: 0;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    font-family: $font-stack;
    height: $timelineCardHeight;
    transition: height $timelineBaseTransitionTime;
    h2 {
        font-size: 2em;
        margin: 0 0 10px 0;
    }
    p {
        font-size: 1.1em;
        letter-spacing: .1px;
        line-height: 1.5em;

        height: 4.5em;
        overflow: hidden;
        margin: 0;

        transition: height $timelineBaseTransitionTime * .8;
    }
    .badge-section {
        right: 0;
        top: $timelineCardHeight - 70px;
        position: absolute;
        height: 70px;
        padding: 10px 30px 15px 30px;
        line-height: 45px;
        margin-right: 40px;
        overflow: hidden;

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;

        $timelineBadgeSectionTransitionTime: $timelineBaseTransitionTime * 1.5;
        transition: left    $timelineBadgeSectionTransitionTime,
                    top     $timelineBadgeSectionTransitionTime,
                    bottom  $timelineBadgeSectionTransitionTime,
                    right   $timelineBadgeSectionTransitionTime,
                    width   $timelineBaseTransitionTime,
                    height  $timelineBadgeSectionTransitionTime,
                    padding $timelineBaseTransitionTime;

        img {
            height: 45px;
            width: 45px;
            margin: 0 2.5px 5em 2.5px;
            border-radius: 50%;
            flex-shrink: 0;
            &:first-child {
                margin-left: 0;
            }
        }
        &.left {
            left: $timelineCardHeight;
        }
        &.right {
            left: 0;
        }
    }
}

.timeline-item.open {
    .timeline-card {
        height: $timelineCardOpenHeight;
        .timeline-content {
            height: $timelineCardOpenHeight;
            p {
                height: 350px;
            }
            .badge-section {
                left: 0;
                top: $timelineCardHeight;
                width: $timelineCardHeight;
                height: $timelineCardOpenHeight - $timelineCardHeight;
                padding: 10px;
                justify-content: center;
                img {
                    margin: 7px;
                }
            }
        }
    }
}
