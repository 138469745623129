:host {
    display: block;
}

.back-button {
    margin-bottom: 2rem;
}

.form-group {
    margin: 0.5rem 0;
}

.file-input-area {
    display: flex;
    margin: 0.5rem 0;
    align-items: center;

    .file-preview {
        width: 300px;
        max-height: 300px;
        margin-right: .5rem;
    }

    .file-input {
        display: none;
    }

    .file-upload {
        display: flex;

        span.filename {
            max-width: 350px;
            display: inline-block;
            overflow: hidden;
            line-height: 40px;
            text-overflow: ellipsis;
        }

        button {
            margin-left: .5rem;
        }
    }
}
