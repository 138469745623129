.local-color { color: #ffd841 }

.icon-section {
    margin-right: 48px;
    padding-top: 19px;
    padding-bottom: 16px;
    height: 75px;
    i {
        line-height: 40px;
    }
}

.user-list {
    .user {
        .mat-list-avatar {
            background-size: contain;
        }
        .subheader {
            color: #909090;
        }
    }
}
