$admin-button-background: deepskyblue;
$admin-button-hover-background: rgba(68, 164, 185, 0.80);
$admin-button-icon-color: rgb(194, 250, 255);

$admin-button-size: 180px;

$admin-button-transition: all 0.4s ease-in-out;

.ch-grid {
    margin: 20px 0 0 0;
    padding: 0;
    list-style: none;
    display: block;
    text-align: center;
    width: 100%;
    &:after { clear: both; }
    li {
        width: $admin-button-size;
        height: $admin-button-size;
        display: inline-block;
        margin: 20px;
        .ch-item {
            background-color: $admin-button-background;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            cursor: default;
            box-shadow: inset 0 0 0 16px rgba(255, 255, 255, 0.6),
                0 1px 2px rgba(0, 0, 0, 0.1);
            transition: $admin-button-transition;
            user-select: none;
            &:hover {
                cursor: pointer;
                box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1),
                    0 1px 2px rgba(0, 0, 0, 0.1);
                .ch-info {
                    transform: scale(1);
                    opacity: 1;
                    p { opacity: 1; }
                }
            }

            .ch-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -40px;
                margin-top: -40px;
                font-size: 80px;
                height: 80px;
                width: 80px;
                color: $admin-button-icon-color;
            }
            .fa-newspaper-o {
                padding-top: 5px;
                font-size: 70px;
            }

            .ch-info {
                position: absolute;
                background: $admin-button-hover-background;
                width: inherit;
                height: inherit;
                border-radius: 50%;
                overflow: hidden;
                opacity: 0;
                transition: $admin-button-transition;
                transform: scale(0);
                text-decoration: none;
                h3 {
                    color: white;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 25px;
                    padding: 45px 0 0 0;
                    height: 140px;
                    width: inherit;
                    font-family: 'Open Sans', Arial, sans-serif;
                    text-shadow: 0 0 1px white,
                    0 1px 2px rgba(0, 0, 0, 0.3);
                }
            }
        }
    }
}
.ch-grid:after,
.ch-item:before {
    content: '';
    display: table;
}

.dashboard-header {
    font-family: $header-font-stack;
    font-size: 30px;
    line-height: 1;
    letter-spacing: -1px;
    font-weight: 700;
    border: none;
    padding: 0;
    margin: 0;
}

.dashboard-donut {
    padding: 0;
}

.dashboard-pie-row > * {
    height: 250px;
}

admin .sidenav-menu li button {
    font-size: 22px;
    width: 100%;
    padding: 10px 0;
}

.admin-outlet-container {
    padding: 2rem;
}
