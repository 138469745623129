$fadeOutAnimationFadeTime: 300ms;

.fade-out.ng-leave {
    opacity: 1;
    transition: opacity $fadeOutAnimationFadeTime linear;
    &.ng-leave-active {
        opacity: 0;
        transition: opacity $fadeOutAnimationFadeTime linear;
    }
}
