:host(project-manager) {
    mat-toolbar {
        background: transparent;
    }
}

mat-card {
    transition: transform .135s cubic-bezier(0,0,.2,1);

    button.checkbox {
        color: transparent;
        position: absolute;
        top: 5px;
        left: 5px;
    }

    &:hover {
        button.checkbox {
            color: rgba(200, 200, 200, .7);

            &:hover {
                color: white;
            }
        }
    }
}

.card-border {
    &.selected {
        background-color: rgba(26, 115, 232, .2);

        button.checkbox {
            color: rgb(26, 115, 232);
        }

        mat-card {
            transform: translateZ(0px) scale3d(0.86, 0.83, 1);
        }
    }
}
