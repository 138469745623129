@import '../variables';
// Colors
// --------------------------------------------------

$btnText: #fff;
$btnTextAlt: #000;

$btnGooglePlusBackground: #dd4b39;
$btnGooglePlusBackgroundHighlight: #c53727;
$btnGithubBackground: #fafafa;
$btnGithubBackgroundHighlight: #ccc;

// Social buttons
// --------------------------------------------------

.btn-google {
    color: $btnGooglePlusBackground;
}

.btn-github {
    color: $btnGithubBackground;
}

mat-form-field {
    width: 100%;
}

mat-sidenav {
    width: 200px;
}
