.photo-toolbar {
    display: flex;
    height: 35px;

    div.item {
        padding: 5px 0;
        flex-grow: 1;
        background-color: #e4e4e4;

        span {
            width: 100%;
            text-align: center;
            font-size: 1.7em;
            padding: 2px 0;
        }
        span.fa-star-o.featured,
        span.fa-star.featured {
            color: #ffff00;
        }
        span.fa-edit {
            color: darkgray;
        }
    }
    div.item:hover {
        background-color: #d0d0d0;

        span.fa-star-o:before,
        span.fa-star-o ~ span:before {
            content: "\f005";
        }
        //        span.fa-edit:before,
        //        span.fa-edit ~ span:before {
        //            content: "\f14b";
        //        }
        span.fa-trash-o:before,
        span.fa-trash-o ~ span:before {
            content: "\f1f8";
        }
    }
}

.grid-gallery ul li figure figcaption {
    padding-top: 10px;
}

.upload-grid {
    display: block;
    list-style: none;
    max-width: 69em;
    margin: 0 auto;
    padding: 0;
    min-height: 300px;
    .upload-grid-item {
        display: inline-block;
        float: left;
        width: 300px;
        overflow: hidden;
        margin: 10px;
        .upload-grid-figure {
            height: 200px;
            overflow: hidden;
            .progress {
                width: 300px;
                margin-top: 100px;
            }
            img {
                width: 300px;
            }
        }
        .upload-grid-caption {
            padding-bottom: 1px;
            overflow: hidden;
            background-color: lightgray;
            position: relative;
            h2 {
                padding: 5px;
            }
            form {
                input {
                    border-radius: 0;
                    border-bottom: none;
                }
            }
            a {
                position: absolute;
                padding: 5px;
                right: 0;
                z-index: 100;
                &:hover {
                    opacity: .7;
                }
            }
        }
        &.deleted {
            .upload-grid-figure {
                opacity: .5;
            }
            .upload-grid-caption {
                background-color: rgba(211,211,211,.5);
                h2 {
                    opacity: .5;
                }
                a {
                    opacity: 1;
                    i:before {
                        color: green;
                        content: "\f0e2";
                    }
                    &:hover {
                        opacity: .7;
                    }
                }
            }
        }
    }
}

mat-card {
    max-width: 300px;
}

.example-header-image {
    background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');
    background-size: cover;
}

button.add-button {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}
