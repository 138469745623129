.post-list {
    .post {
        .subheader {
            color: #909090;
        }
    }
}


table[mat-table] .mat-column-actions {
    box-sizing: content-box;
    flex: none;
    // fits 2 actions
    width: 48px * 2;
}
