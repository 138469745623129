/**
 * App-wide Variables
 */

@import '@angular/material/theming';

$font-stack: 'Open Sans', sans-serif;
$header-font-stack: 'Roboto', sans-serif;

$subtle-border: 1px solid #e5e5e5;

/**
 * Lengths
 */

    /**
     * Footer
     */
    $footer-height: 61px;

/**
 * Colors
 */

    /**
     * General
     */
    $ak-base-color-darker   : #428bca;
    $ak-base-color-darkest  : #3e709e;
    $border-color           : #1d1d1d;
    $text-black             : #141414;
    $text-muted             : #999999;
    $link-color             : #4B85AE;

    $darkPrimaryColor       : #0288D1;
    $primaryColor           : #03A9F4;
    $lightPrimaryColor      : #B3E5FC;
    $accentColor            : #FF5252;
    $darkTextColor          : #212121;
    $lightTextColor         : #FFFFFF;
    $secondaryTextColor     : #727272;
    $dividerColor           : #B6B6B6;

    /**
     * Brands
     */
    $googlePlusColor        : #dd4b39;
    $gitHubColor            : #333333;
    $linkedInColor          : #0077b5;

/**
 * Breakpoints
 */

$breakpoint-xs: 600px !default;
$breakpoint-sm: 960px !default;
$breakpoint-md: 1280px !default;
$breakpoint-lg: 1920px !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$primary: mat-palette($mat-light-blue);
$accent: mat-palette($mat-red, A200, A100, A400);
$warn: mat-palette($mat-red);

$angular-default-theme: mat-light-theme(
        (
            color: (
                primary: $primary,
                accent: $accent,
                warn: $warn,
            ),
        )
);

$angular-dark-theme: mat-dark-theme(
        (
            color: (
                primary: $primary,
                accent: $accent,
                warn: $warn,
            ),
        )
);
